.session-form-rich-text  {
  pointer-events: auto;
  
  [class^="RichTextEditor__editor"] {
    height: 280px;
  }
}

.ai-session-button {
  background-image: linear-gradient(to bottom right, #f472b6, #a855f7) !important;
  &:hover {
    background-image: linear-gradient(to bottom right, #f9a8d4, #c084fc) !important;
  }
}