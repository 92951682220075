.post-referral-link-modal-container {
  max-height: 80%;
  overflow-y: auto;
}

.internal-deeplink-container {
  .internal-deeplink {
    padding: 2px 8px;
    border: 1px dotted #f59e31;
    color: #f59e31;
  }
}