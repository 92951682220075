.Wizard-container {
  //left-side wizard structure styling
  counter-reset: section;
  .progressContainer {
    align-content: center;
    position: relative;
    width: auto;
    height: auto;
    margin: 0 auto;
    overflow: hidden;
    padding: 2rem;
    color: #fff;
    background-color: #ffffff !important;
  }

  .progress {
    position: relative;
    padding: 0 1rem 0 3.5rem;
    margin: 2rem 0 0;
    list-style: none;
    background: #181a35;
    margin-top: 0px;
    width: 92%;
    padding-top: 30px;
    height: 100%;
  }
  .progress__item {
    position: relative;
    min-height: 75px;
    counter-increment: section;
    padding-left: 0.5rem;
    justify-content: center;
    align-content: center;
    position: middle;
  }
  .progress__item:before {
    content: "";
    position: absolute;
    left: -1.5rem;
    top: 33px;
    height: 60%;
    width: 1px;
    border-left: 1px solid #fff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  }
  .progress__item:after {
    content: counter(section);
    position: absolute;
    top: 0;
    left: 17.9rem;
    width: 56px;
    height: 52px;
    border-radius: 50%;
    background: transparent;
    color: #fff;
    font-weight: 400;
    font-size: 13px;
    line-height: 3rem;
    text-align: center;
    border: 1px solid #fff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  }
  .progress__item:last-child:before {
    border: none;
  }
  .progress__item.progress__item--active:after {
    background: linear-gradient(270deg, #75388d 0%, #3f84c5 100%);
    border: 1px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    font-weight: bold;
    font-size: 16px;
  }
  .progress__item.progress__item--inactive:after {
    background: #fff;
    color: rgba(29, 29, 45, 0.2);
    font-weight: bold;
    font-size: 16px;
  }
  .Background-rectangle-image.wizard-autism-side-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    padding: 0px;
    height: 100vh;

    .inactive-welcome-text-container {
      color: #808080;
      font-size: 24px;
      font-weight: bold;
      line-height: 24px;
      cursor: pointer;
      p {
        margin-top: 35px;
        margin-bottom: 0px;
      }
      span {
        color: #808080;
        font-size: 16px;
        font-weight: normal;
      }
    }
    .active-welcome-text-container {
      color: #00adee;
      font-size: 24px;
      font-weight: bold;
      line-height: 24px;
      cursor: pointer;
      p {
        margin-top: 35px;
        margin-bottom: 0px;
      }
      span {
        color: #ffffff;
        font-size: 16px;
        font-weight: normal;
      }
    }
  }

  //right-side wizard structure styling
  .welcomeContainer {
    .click-prev-page {
      cursor: pointer;
      opacity: 0.5;
    }
    .resp-container {
      .welcome-video-text {
        font-weight: bold;
        font-size: 32px;
        line-height: 100%;
        padding: 12px;
        letter-spacing: 0.1px;
        color: #000000;
      }
    }
    .ant-list-item {
      display: block !important;
    }

    .ant-list-item-meta-title {
      font-weight: bold;
      font-size: 32px;
      line-height: 100%;
      letter-spacing: 0.1px;
      color: #000000;
    }
    .ant-list-item-meta-description {
      font-weight: bold;
      font-size: 32px;
      line-height: 100%;
      letter-spacing: 0.1px;
      color: #1d1d2d;
      opacity: 0.5;
    }
    .click-next-page {
      cursor: pointer;
    }
    .uploadfileStyle {
      width: 100%;
      height: 340px;
      background: rgba(0, 173, 238, 0.05);
      border: 1px solid rgba(29, 29, 45, 0.1);
      box-sizing: border-box;
      border-radius: 10px;
      font-size: 16px;
      justify-content: space-between;
      line-height: 20px;
      display: flex;
      letter-spacing: 0.1px;

      span.anticon.anticon-upload {
        // color: rgba(0, 0, 0, 0.5) !important;
        // font-size: 50px;
        // opacity: 0.3;
        display: block;
      }

      // .ant-upload.ant-upload-select .ant-upload span:last-child {

      //   color: #242C36 !important;
      // }
      .support-image-file-type {
        color: rgba(0, 0, 0, 0.5) !important;
        font-size: 13px;
      }
    }

    .timezoneSearchStyle {
      background: #ffffff !important;
      border: 1px solid rgba(29, 29, 45, 0.1);
      box-sizing: border-box;
      border-radius: 27px;
      width: 100%;
      top: 35px;
      box-shadow: none !important;
      .timeZoneStyle {
        width: 100%;
        color: black !important;
        box-shadow: none !important;
        padding-left: 12px !important;
      }
      .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        box-shadow: none !important;
      }
      .ant-select-single.ant-select-open span.ant-select-arrow {
        position: absolute;
        left: 0px;
        color: #ea197a !important;
        font-size: 20px;
      }
      .ant-select-arrow {
        color: black !important;
      }
    }

    .getStartedContainer {
      padding-right: 24px;

      img.appScreenshots {
        @media (max-width: 640px) {
          max-width: 100%;
        }
        max-width: 640px;
        max-height: calc(100vh - 400px);
        margin: 12px 0;
      }
      .downloadButtonContainer {
        a {
          margin: 12px;
          text-align: center;
        }
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        max-width: 640px;
      }
    }
  }
}
.ant-list-empty-text {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}
@media only screen and (max-width: 992px) {
  .Wizard-container {
    //left-side wizard structure styling
    counter-reset: section;
    .burgerIcon {
      padding: 22px 31px 55px 30px;
      background: none !important;
      border: none !important;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    }
    .menuMobileView {
      position: fixed;
      z-index: 9999999;
    }
    .right-mobile-view-Container {
      padding: 0px !important;
      z-index: 99;
    }
    .progressContainer {
      align-content: center;
      position: relative;
      width: auto;
      height: auto;
      margin: 0 auto;
      overflow: hidden;
      padding: 2rem;
      color: #fff;
      background-color: #ffffff !important;
    }
    .progress {
      position: relative;
      padding: 0 1rem 0 3.5rem;
      margin: 2rem 0 0;
      list-style: none;
    }
    .progress__item {
      position: relative;
      min-height: 75px;
      counter-increment: section;
      padding-left: 0.5rem;
      justify-content: center;
      align-content: center;
      position: middle;
    }
    .progress__item:before {
      content: counter(section);
      position: absolute;
      top: 0;
      left: -4.5rem;
      width: 50px;
      height: 45px;
      border-radius: 50%;
      background: transparent;
      color: #fff;
      font-weight: 400;
      font-size: 13px;
      line-height: 3rem;
      text-align: center;
      border: 1px solid #fff;
    }
    .progress__item:after {
      display: none;
    }
    .progress__item:last-child:before {
      border: none;
    }
    .progress__item.progress__item--active:before {
      background: linear-gradient(270deg, #75388d 0%, #3f84c5 100%);
      border: 1px solid #ffffff;
      box-sizing: border-box;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
      font-weight: bold;
      font-size: 16px;
    }
    .progress__item.progress__item--inactive:before {
      background: #fff;
      color: rgba(29, 29, 45, 0.2);
      font-weight: bold;
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 599px) and (min-width: 359px) {
  // .welcomeContainer {
  //   .click-next-page {
  //     cursor: pointer;
  //     bottom:22px !important;
  //   }
  //   .click-prev-page {
  //     cursor: pointer;
  //     bottom:22px !important;
  //   }
  //   .uploadfileStyle {
  //     display: unset !important;
  //     background: transparent !important;
  //     border:none !important;
  //     box-shadow:none !important;
  //     .upload-outer-container{
  //       width:auto !important;
  //       height:250px;
  //       .ant-upload-list-text-container{
  //         width:190px;
  //         margin-left:10px;
  //       }
  //     }
  //     .capture-webscan-container{
  //       width:auto !important;
  //       margin-top:9px;
  //       height: 250px;
  //     }
  //   }
  // }

  .Signin-container {
    .Background-rectangle-image {
      height: 250px !important;
    }
    .welcomeText {
      margin-top: 15px !important;
      .SignInText {
        font-size: 16px !important;
        line-height: 8px !important;
      }
    }
    .welcomeContainer {
      .resp-container {
        .welcome-video-text {
          font-size: 16px !important;
          padding: 0px !important;
        }
      }
      .ant-list-item-meta-title {
        font-size: 16px !important;
      }
      .wizard-modal-upload-button {
        margin-top: 0px !important;
      }
      .click-next-page {
        cursor: pointer;
        font-size: 16px !important;
        > img {
          width: 16px !important;
        }
      }
      .welcome-user-image {
        .click-prev-page {
          cursor: pointer;
          font-size: 16px !important;
          > img {
            width: 16px !important;
          }
        }
      }
    }
  }
  .uploadfileStyle {
    display: unset !important;
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
    .upload-outer-container {
      width: auto !important;
      height: 180px;
      .show-upload {
        margin-left: 48px !important;
        border-radius: 28px !important;
        height: 36px !important;
        font-size: 16px !important;
        width: 38px !important;
        margin-top: 8px !important;
        padding-top: 10px !important;
      }
      .upload-profile-wizard {
        font-size: 10px !important;
        line-height: 20px !important;
      }
      .support-image-file-type {
        font-size: 9px !important;
      }
      .ant-upload-list-text-container {
        width: 190px;
        margin-left: 10px;
      }
    }
    .capture-webscan-container {
      width: auto !important;
      margin-top: 9px;
      height: 180px;
      .show-webcam {
        margin-left: 50px !important;
        border-radius: 28px !important;
        height: 36px !important;
        font-size: 16px !important;
        width: 38px !important;
        margin-top: -106px !important;
        padding-top: 8px !important;
      }
      .click-profile-wizard {
        font-size: 10px !important;
        line-height: 20px !important;
      }
      .support-image-file-type {
        font-size: 9px !important;
      }
      .wizard-buttons {
        padding: 4px !important;
        font-size: 10px !important;
        border-radius: 8px !important;
      }
    }
    .webcam-container img {
      height: 125px;
    }
  }
}
@media only screen and (max-width: 359px) and (min-width: 300px) {
  .Signin-container {
    .Background-rectangle-image {
      height: 250px !important;
    }
    .welcomeText {
      margin-top: 15px !important;
      .SignInText {
        font-size: 16px !important;
        line-height: 8px !important;
      }
    }
    .welcomeContainer {
      .resp-container {
        .welcome-video-text {
          font-size: 16px !important;
        }
      }
      .ant-list-item-meta-title {
        font-size: 16px !important;
      }
      .wizard-modal-upload-button {
        margin-top: 0px !important;
      }
      .click-next-page {
        cursor: pointer;
        bottom: 22px !important;
        font-size: 16px !important;
        > img {
          width: 16px !important;
        }
      }
      .welcome-user-image {
        .click-prev-page {
          cursor: pointer;
          bottom: 22px !important;
          font-size: 16px !important;
          > img {
            width: 16px !important;
          }
        }
      }
    }
  }
  .uploadfileStyle {
    display: unset !important;
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
    .upload-outer-container {
      width: auto !important;
      height: 180px;
      .show-upload {
        margin-left: 48px !important;
        border-radius: 28px !important;
        height: 36px !important;
        font-size: 16px !important;
        width: 38px !important;
        margin-top: 8px !important;
        padding-top: 10px !important;
      }
      .upload-profile-wizard {
        font-size: 10px !important;
        line-height: 20px !important;
      }
      .support-image-file-type {
        font-size: 9px !important;
      }
      .ant-upload-list-text-container {
        width: 190px;
        margin-left: 10px;
      }
    }
    .capture-webscan-container {
      width: auto !important;
      margin-top: 9px;
      height: 180px;
      .show-webcam {
        margin-left: 50px !important;
        border-radius: 28px !important;
        height: 36px !important;
        font-size: 16px !important;
        width: 38px !important;
        margin-top: -106px !important;
        padding-top: 8px !important;
      }
      .click-profile-wizard {
        font-size: 10px !important;
        line-height: 20px !important;
      }
      .support-image-file-type {
        font-size: 9px !important;
      }
      .wizard-buttons {
        padding: 4px !important;
        font-size: 10px !important;
        border-radius: 8px !important;
      }
    }
    .webcam-container {
      height: 80%;
      img {
        height: 90px;
        width: 170px;
      }
    }
  }
}
