.InputFieldsContainer{
    background: #FFFFFF;
    border: 1px solid #d0d0e2;
    border-radius: 16px;
    color: #000000;
    width: 500px;
}


@media only screen and (max-width: 992px) { 

    .InputFieldsContainer{
        background: #FFFFFF;
        border: 1px solid #d0d0e2;
        border-radius: 16px;
        color: #000000;
        width: 100%;
    }

}