
.manage-form-style{
  
    
.ant-row{
  .ant-col{
    .ant-form-item-control-input{
      .ant-select{
        .ant-select-selector{
          border:none;
           border-bottom: 1px solid #E9E9E9;
          .ant-select-selection-search{
            input{
              border:none;
             border-bottom: 1px solid #E9E9E9;
            }
          }
        }
      }
      .ant-form-item-control-input-content{
        textarea.ant-input{
          background: #FFFFFF;
          border: 1px solid rgba(29, 29, 45, 0.1);
          box-sizing: border-box;
          border-radius: 20px;
        }
      }
    }
    .ant-row{
      .ant-col{
        .ant-form-item-control-input{
          .ant-form-item-control-input-content{
             border-bottom: 1px solid #E9E9E9;
            .ant-picker {
              border: none;
            }
            .ant-select{
              .ant-select-selector{
                border:none;
                border-bottom: 1px solid #E9E9E9;
              }
            }
          }
        }
      }
    }
  }
}

  .session-notes .time-zone-container-s{
    margin-bottom:0px;
  }

  .time-zone-container-s .ant-form-item-control-input-content{
    display:flex;
  }
  .time-zone-container-s .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    right: 10px;
    z-index: 999;
 }
  .time-zone-container-s .ant-form-item-control-input-content img {
    position: absolute;
    right: 12px;
    top: 5px;
  }
  .titleStyle {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold !important;
    font-size: 20px !important;
    line-height: 100% !important;
    letter-spacing: 0.1px !important;
    color: $welcome-text-color !important;
    margin-bottom: 20px;
 }
 .form-date-time-text{
  font-family: Mulish;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #1D1D2D;
 }
 .autismag-required-field::before {
  display: inline-block;
  margin-right: 4px;
  color: $validation-require-color !important;
  font-size: 18px !important;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.autismag-required-field {
   font-family: Mulish;
    font-style: normal;
    font-weight: bolder;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #1D1D2D;
}
.Check-goal-as-primary{
  .ant-checkbox{
    .ant-checkbox-inner{
      border: 1px solid #EAECF1;
      box-sizing: border-box;
      border-radius: 5px;
      box-shadow:0px 5px 5px rgb(0 0 0 / 16%);
    }
  }
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-right: 4px;
  color: $validation-require-color !important;
  font-size: 16px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

    .ant-form-item{
      margin-bottom: 18px;
      .ant-form-item-label{
        label{
          font-family: Mulish;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.1px;
          color:$form-Heading-color;
        }
      }
    
    .ant-form-item-control-input{
      .ant-form-item-control-input-content{
        // border-bottom: 1px solid #E9E9E9;
        input{
          border: none;
        }
      }
    }
  
  }

  .form-date-time-row {
    .ant-picker-input{
      margin-bottom: 5px;
    }
    .ant-form-item {
      margin-bottom: 18px;
    }
  }

  .no-boder .ant-form-item-control-input-content{
    border-bottom: none !important
    
  }

  .ant-select-multiple .ant-select-selection-item{
    border-radius: 20px;
    background: #FFFFFF;
    border: 1px solid rgba(29, 29, 45, 0.5);
  }

  .drawer-footer-style {
    border: none;
    .ant-form-item-control-input-content {
      border: none !important;
    }
  }

}
.author-form-style{
  
    
.ant-row{
  .ant-col{
    .ant-form-item-control-input{
      .ant-select{
        .ant-select-selector{
          border:none;
          .ant-select-selection-search{
            input{
              border:none;
             border-bottom: 1px solid #E9E9E9;
            }
          }
        }
      }
      .ant-form-item-control-input-content{
        textarea.ant-input{
          background: #FFFFFF;
          border: 1px solid rgba(29, 29, 45, 0.1);
          box-sizing: border-box;
          border-radius: 20px;
        }
      }
    }
    .ant-row{
      .ant-col{
        .ant-form-item-control-input{
          .ant-form-item-control-input-content{
             border-bottom: 1px solid #E9E9E9;
            .ant-picker {
              border: none;
            }
            .ant-select{
              .ant-select-selector{
                border:none;
                border-bottom: 1px solid #E9E9E9;
              }
            }
          }
        }
      }
    }
  }
}

  .session-notes .time-zone-container-s{
    margin-bottom:0px;
  }

  .time-zone-container-s .ant-form-item-control-input-content{
    display:flex;
  }
  .time-zone-container-s .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    right: 10px;
    z-index: 999;
 }
  .time-zone-container-s .ant-form-item-control-input-content img {
    position: absolute;
    right: 12px;
    top: 5px;
  }
  .titleStyle {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold !important;
    font-size: 20px !important;
    line-height: 100% !important;
    letter-spacing: 0.1px !important;
    color: $welcome-text-color !important;
    margin-bottom: 20px;
 }
 .form-date-time-text{
  font-family: Mulish;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #1D1D2D;
 }
 .autismag-required-field::before {
  display: inline-block;
  margin-right: 4px;
  color: $validation-require-color !important;
  font-size: 18px !important;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.autismag-required-field {
   font-family: Mulish;
    font-style: normal;
    font-weight: bolder;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #1D1D2D;
}
.Check-goal-as-primary{
  .ant-checkbox{
    .ant-checkbox-inner{
      border: 1px solid #EAECF1;
      box-sizing: border-box;
      border-radius: 5px;
      box-shadow:0px 5px 5px rgb(0 0 0 / 16%);
    }
  }
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-right: 4px;
  color: $validation-require-color !important;
  font-size: 16px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

    .ant-form-item{
      margin-bottom: 18px;
      .ant-form-item-label{
        label{
          font-family: Mulish;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.1px;
          color:$form-Heading-color;
        }
      }
    
    .ant-form-item-control-input{
      .ant-form-item-control-input-content{
        // border-bottom: 1px solid #E9E9E9;
        input{
          border: none;
        }
      }
    }
  
  }

  .form-date-time-row {
    .ant-picker-input{
      margin-bottom: 5px;
    }
    .ant-form-item {
      margin-bottom: 18px;
    }
  }

  .no-boder .ant-form-item-control-input-content{
    border-bottom: none !important
    
  }

  .ant-select-multiple .ant-select-selection-item{
    border-radius: 20px;
    background: #FFFFFF;
    border: 1px solid rgba(29, 29, 45, 0.5);
  }

  .drawer-footer-style {
    border: none;
    .ant-form-item-control-input-content {
      border: none !important;
    }
  }

}

.edit-user-profile-form-container {
  .ant-col.ant-col-8:last-child {
    .ant-col.ant-col-16.ant-form-item-control {
      max-width: 100%;
    }
  }
  .ant-col.ant-col-12:last-child {
    .ant-col.ant-col-16.ant-form-item-control {
      max-width: 100%;
    }
  }
  .edit-profile-user-status-field {
    .ant-form-item .ant-col.ant-col-16.ant-form-item-control {
      max-width: 97.5%;
    }
  }
  .ant-form-item {
    display: block;
    .ant-form-item-label {
      max-width: 100%;
      label::after {
        display: none;
      }
    }
    .ant-form-item-control-input {
      width: 100%;
      .edit-user-upload-profile-photo {
        width: 100%;
        height: 500px;
        border: 1px solid rgba(29, 29, 45, 0.1);
        border-radius: 10px;
      }
      .view-avatar {
        width: 100%;
        height: 500px;
        border: 1px solid rgba(29, 29, 45, 0.1);
        border-radius: 10px;
        span.ant-avatar.ant-avatar-square.ant-avatar-image {
          width: 100%;
          height: 100%;
          padding: 5px;
          border-right: 10px;
        }
      }
      .view-avatar:hover .icon-box {
        overflow: hidden;
        float: left;
        width: 120px;
        height: 70px;
        position: absolute;
        right: 5px;
        left: auto;
        top: 5px;
        color: #fff;
        opacity: 1;
        background-color: transparent;
        .icons-span {
          top: 12px;
          a {
            background: #FFFFFF;
            border: 1px solid #EAECF1;
            box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
            border-radius: 5px;
            padding: 10px 0px;
            color: #67358e;
          }
          span.anticon.anticon-delete.action-icon {
            background: #FFFFFF;
            border: 1px solid #EAECF1;
            box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
            border-radius: 5px;
            margin-left: 12px;
            color: #ea1a7a;
          }
        }
      }
    }
    .ant-col.ant-col-16.ant-form-item-control {
      width: 100%;
      max-width: 95%;
    }
    .ant-input[disabled] {
      background: none;
    }
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background: none;
    }
    .ant-select-selector {
      .ant-select-selection-search input {
        border: none !important;
      }
    }
    .ant-select {
      .ant-select-arrow {
        color: #e9e9e9;
      }
    }
    .edit-user-phone-number-field {
      border-bottom: 1px solid #e9e9e9 !important;
      height: 32px;
    }
    .ant-form-item-control-input-content textarea.ant-input {
      border-radius: 10px !important;
    }
  }
  .edit-user-profile-member-outer-container {
    background: #FFFFFF;
    border: 1px solid rgba(29, 29, 45, 0.1);
    border-radius: 10px;
    width: 98%;
    padding: 25px;
    height: 450px;
    overflow-y: scroll;
    .ant-col.ant-col-16.ant-form-item-control {
      max-width: 100% !important;
    }
    .ant-form {
      .customer-children {
        border: none;
        padding: 0px;
        .ant-col.ant-col-12 .ant-col.ant-form-item-control {
          max-width: 95%;
          width: 100%;
        }
        .ant-col.ant-col-12:last-child {
          .ant-col.ant-form-item-control {
            max-width: 100%;
          }
        }
      }
    }
  }
}

.resource-form .ant-col .ant-col-16{
  max-width: 100% !important;
}

.edit-user-profile-main-drawer-container {
  .ant-drawer-header {
    margin: 25px;
    padding: 16px 0px;
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 992px) {
  .edit-user-profile-form-container {
    .ant-col-8 {
      max-width: 100%;
      flex: 100%;
    }
    .ant-col-12 {
      max-width: 100%;
      flex: 100%;
    }
    .ant-col-16 {
      max-width: 100%;
      flex: 100%;
    }
    .ant-col.ant-col-16.ant-form-item-control {
      max-width: 100% !important;
    }
    .edit-user-profile-member-outer-container {
      width: 100%;
      padding-bottom: 0px;
      margin-bottom: 20px;
      .ant-col.ant-col-12 .ant-col.ant-form-item-control {
        max-width: 100% !important;
      }
    }
    .ant-form-item .ant-form-item-control-input .view-avatar {
      height: 250px;
    }
  }
  .edit-user-avatar-form-customer {
    position: absolute;
    width: 100%;
    top: 80px;
}

.edit-user-profile-form-container .edit-user-profile-member-outer-container {
    position: relative;
    top: 560px;
}
}
@media only screen and (max-width: 600px) {
  .form-date-time-row{
     .left-select{
       flex:50%;
       max-width: 50%;
     }
  }

}

.dropzone-full{
  width: 100% !important;
  height: 100% !important;
}

.blog-footer{
  width: 1024px  !important;
}

.category-selector .ant-select-selector,
.category-selector .ant-select-selection-search,
.category-selector .ant-select-selection-item {
  height: 52px !important;
}