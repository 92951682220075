.user-drawer{
      
    .ant-drawer-content {
        border-radius: 20px 0px 0px 20px !important;
    }
    .ant-drawer-title{
        font-family: Mulish;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 100%;
        letter-spacing: 0.1px;
        color: $form-Heading-color;
    }
    .ant-form-item-label{
        font-family: Mulish;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.1px;
    }
    .drawer-footer-style .ant-form-item-control-input-content{
        display: flex;
        justify-content: center;
    }
    .drawerButton .ant-btn {
        width: 225px;
        height: 48px;
        background: $button-color;
        border:none !important;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        font-family: Mulish;
        font-style: normal;
        letter-spacing: 0.1px;
        color: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(29, 29, 45, 0.25);
        border-radius: 24px;
    }
    .taskformfooter{
        width:560px !important;
    }
    .drawer-footer-style{
        border-radius: 20px 0px 0px 20px !important;
    }
    .edit-user-drawer-footer-style {
        width: 88%;
        display: flex;
        justify-content: center;
        .drawer-footer-style .ant-form-item {
            display: flex;
            justify-content: center;
        }
        .edit-user-save-button {
            width: 250px;
        }
    }
    .close-drawer-button{
        position: fixed;
        top: 135px;
        right: 600px;
        background: none !important;
        box-shadow: none !important;
        width:auto !important;
        height:auto !important;
        padding: 0px !important;

    }
    .edit-profile-close-drawer-button {
        right: unset;
        left: 40px;
    }
    .hide-close-drawer-button{
        display:none;
    }
}

.session-drawer {
    .close-drawer-button {
        right: 0px !important;
        left: auto !important;
        top: 0px !important;
    }
}