.mileStone-container {
  box-sizing: border-box;
  // box-shadow: 0px 0px 16px rgba(0, 0, 0, 6%);
  border: 1px solid rgba(29, 29, 45, 0.1);
  border-radius: 20px;
  padding: 24px;

  .add-milestone {
    justify-content: flex-start !important;
    padding-bottom: 20px;
    margin: 0px;
    .add-milestone-button {
      background: #f5b04a;
      box-shadow: 0px 4px 4px rgb(29 29 45 / 25%);
      border-radius: 20px;
      color: #ffffff;
      position: absolute;
      right: 65px;
      top: 45px;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.1px;
      height: 40px;
    }
    .add-goal-button {
      background: #f5b04a;
      box-shadow: 0px 4px 4px rgb(29 29 45 / 25%);
      border-radius: 20px;
      color: #ffffff;
      right: 22px;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.1px;
    }
    .milestone-label {
      .titleStyle {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 100%;
        letter-spacing: 0.1px;
        color: $welcome-text-color;
      }
    }
  }
  .mileStone-panel-text {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: $form-Heading-color;
    text-overflow: ellipsis;
    
    .mileStone-text {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 120px;
    }
  }
  .ant-collapse-item-active {
    .mileStone-panel-text {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: $validation-require-color;
    }
  }
  .checkBoxWiddth {
    .ant-checkbox {
      background: #ffffff;
      border: 1px solid #eaecf1;
      box-sizing: border-box;
      border-radius: 5px;
      box-shadow: 0px 4px 2px rgb(0 0 0 / 5%);
    }
    .ant-checkbox-checked {
      background: #00adee;
      border-radius: 5px;
      box-shadow: 0px 4px 2px rgb(0 0 0 / 5%);
    }
  }
  .is-complete-task {
    .taskData-text {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: $form-Heading-color;
    }
  }
  .custom-panel {
    .ant-collapse-content {
      padding: 0px 25px 0px 25px;
      border-top: none;
      background: transparent;
    }
    .ant-collapse-content-box {
      background: rgba(0, 173, 238, 0.05);
      border-radius: 0px 0px 10px 10px;
      padding: 25px;
    }
    .task-view-edit-icon {
      cursor: pointer;
      span {
        padding-left: 15px;
      }
    }
  }
  .button-add-task {
    background: #00adee !important;
    box-shadow: 0px 4px 4px rgb(29 29 45 / 25%);
    border-radius: 20px;
    color: #ffffff;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.1px;
    height: 40px;
  }
  .milestone-view-edit {
    .edit-button-bussiness {
      padding-left: 20px;
    }
  }
  .ant-collapse {
    border-radius: 10px;
    background-color: transparent;
    border: none;
    padding: 0px;
    .ant-collapse-item.custom-panel {
      padding-top: 0px;
      padding-bottom: 7px;
      // border: 1px solid rgba(29, 29, 45, 0.1);
      // border-radius: 10px;
      margin-bottom: 10px;
      border: none;
      .ant-collapse-header {
        border: 1px solid rgba(29, 29, 45, 0.1);
        border-radius: 10px;
        padding-bottom: 20px;
        padding-top: 17px;
        img.ant-collapse-arrow {
          // padding-top: 15px;
          padding-left: 10px;
        }
      }
    }
  }
  .ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid rgba(29, 29, 45, 0.1);
    padding-bottom: 3px;
    padding-top: 10px;
  }
}

.myGoalMilestoneOuter {
  padding-left: 48px !important;
}

.my-goal-table .ant-table-column-sorters {
  font-size: 16px;
  line-height: 20px;
  color: #242c36;
  font-weight: bold;
}

.my-goal-table
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child {
  font-size: 16px;
  line-height: 20px;
  color: #242c36;
  font-weight: bold;
}

.Add-task-modal-container {
  width: 370px !important;
  .ant-modal-content {
    background: #ffffff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    .ant-modal-header {
      border-radius: 20px;
      border: none;
      padding-top: 30px;
      .ant-modal-title {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #242c36;
        border-bottom: 1px solid #e9e9e9;
        padding-bottom: 20px;
        .add-task-title-container {
          display: flex;
          position: relative;
          .add-task-close-button {
            position: absolute;
            right: 0px;
            cursor: pointer;
            font-size: 13px;
          }
        }
      }
    }
    .ant-modal-body {
      padding-top: 10px;
      padding-bottom: 0px;
      textarea#notes {
        background: #ffffff;
        border: 1px solid rgba(29, 29, 45, 0.1);
        box-sizing: border-box;
        border-radius: 20px;
        height: 300px;
        padding: 10px;
      }
    }
    .ant-modal-footer {
      display: flex;
      justify-content: center;
      border: none;
      padding-bottom: 30px;
      button {
        width: 192px;
        height: 48px;
        background: #f5b04a;
        box-shadow: 0px 4px 4px rgba(29, 29, 45, 0.25);
        border-radius: 24px;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #ffffff;
        border: none;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .milestone-mobile-container-tab .ant-collapse-header{
   display: flex;
  }
  .mileStone-mobile-container {
    box-sizing: border-box;
    // box-shadow: 0px 0px 16px rgba(0, 0, 0, 6%);
    background: #f8fbfc;
    border-radius: 10px;
    margin-left: 20px;
    .mileStone-mobile-text {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 50%;
    }
.is-completed-viewTask{
    font-size: 13px !important;
      color: $form-Heading-color !important;
}
    .mileStone-panel-text {
      font-weight: bold;
      font-size: 13px;
      line-height: 12px;
      letter-spacing: 0.1px;
      color: #1d1d2d;
    }
    .ant-collapse-item-active {
      .mileStone-panel-text {
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: $validation-require-color;
      }
    }
    .autismag-table-action {
      // width: 55px;
      display: flex;
      justify-content: space-around;
    }
    .autismag-table-action span:last-child {
      padding-left: 8px;
    }
    .mileStone-add-drawer-container-tab {
      display: flex;
      justify-content: flex-end;
      .add-milestone-button {
        margin-right: 0px;
      }
    }
    .titleStyle {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
    }
    .goal-table-text {
      font-weight: normal;
      font-size: 13px !important;
    }

    .goal-table-text.goal-primary{
      color: #00ADEE !important;
    }
    span.lastActivity {
      font-weight: normal;
      font-size: 13px !important;
    }
    .ant-row {
      border-bottom: 1px solid #e9e9e9;
      padding: 20px;
      margin: auto !important;
    }
    .ant-row:nth-child(5) {
      border: none;
    }
    .ant-collapse-item {
      margin-bottom: 15px;
      border: none;
    }
  }
  .milestone-text {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 13px;
    letter-spacing: 0.1px;
    color: #000000;
    display: flex;
    align-items: center;
  }

  .goal-table-date {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #1d1d2d;
  }
  .highly-ambitious-mobile-view {
    background: rgba(103, 52, 142, 0.1);
    border: 1px solid rgba(103, 52, 142, 0.2);
    box-sizing: border-box;
    border-radius: 11px;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    text-align: center;
    line-height: 13px;
    padding: 5px 15px;
    letter-spacing: 0.1px;
    color: #67348e;
    flex-direction: row;
    justify-content: center;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .titleStyle {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 100%;
    letter-spacing: 0.1px;
    color: $welcome-text-color;
  }
  .complex-style-mobile-view {
    background: rgba(0, 173, 238, 0.1);
    border: 1px solid rgba(0, 173, 238, 0.2);
    box-sizing: border-box;
    border-radius: 11px;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.1px;
    color: #00adee;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 15px;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .my-goal-table {
    .ant-table-container {
      background: #ffffff;
      border-radius: 10px;
    }
    .ant-table-thead {
      display: none;
    }
    .expand-icon-class {
      right: -140px;
      position: absolute;
    }
    td.ant-table-cell.ant-table-row-expand-icon-cell {
      min-width: 60px;
      padding-right: 0px;
    }
    .goal-table-text {
      font-size: 15px !important;
      width: 60%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .goal-table-text.goal-primary{
      color: #00ADEE !important;
    }

  }
  .mobile-goal-page-table-container td.ant-table-cell:last-child {
    padding-left: 0px;
  }
  .milestone-mobile-container-tab {
    display: flex;
    flex-direction: column;
    padding: 0px 30px;
    .ant-collapse {
      border: none;
    }
    .ant-collapse-header {
      background: #fff;
      border-radius: 5px !important;
      height: 65px;
      img.ant-collapse-arrow {
        padding-top: 11px !important;
      }
      .ant-collapse-arrow {
        line-height: 26px;
      }
    }
    .ant-collapse-content {
      border: none;
      margin: 0px 20px;
      background: #f2fbfe;
    }
    .task-mobile-container-text {
      flex: 1;
      width: 100%;
      flex-direction: row;
      border-bottom: 1px solid #e9e9e9;
      padding-bottom: 20px;
      padding-top: 10px;
      position: relative;
      .task-drawer-show-icon-container {
        position: absolute;
        right: 0px;
        top: 7px;
        .task-view-edit-icon {
          cursor: pointer;
          span {
            margin-right: 10px;
          }
        }
      }
    }
    .mileStone-panel-text {
      // position: relative;
      // padding-top: 10px;

      .milestone-drawer-icon-container-tab {
        position: absolute;
        right: 0px;
        top: 0px;
        .milestone-view-edit {
          span {
            margin-right: 10px;
          }
        }
        .button-add-task {
          background: #00adee !important;
          border-radius: 15px;
          font-weight: bold;
          font-size: 11px;
          margin: 0px 10px;
          color: #fff !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .milestone-mobile-container-tab {
    display: flex;
    flex-direction: column;
    padding: 0px 15px;
  }
  .mileStone-mobile-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 80px;
  }
}
@media only screen and (max-width: 400px) {
  .milestone-mobile-container-tab {
    display: flex;
    flex-direction: column;
    padding: 0px 0px;
    .task-mobile-container-text label {
      // text-overflow: ellipsis;
      // overflow: hidden;
      // white-space: nowrap;
      // width: 105px;
    }
  }
  .mileStone-mobile-container {
    box-sizing: border-box;
    background: #f8fbfc;
    border-radius: 10px;
    margin-left: 0px;
    .titleStyle {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
  .mileStone-mobile-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 50px;
  }
  .mileStone-add-drawer-container-tab {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media only screen and (max-width: 1400px) {
  .task-mobile-container-text {
    label > span:nth-child(2) {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .not-completed-task {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    // width: 50%;
    .taskData-text {
      color: #787b88 !important;
    }
  }
  .is-complete-task {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    // width: 50%;
  }
  .is-completed-viewTask{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    // width: 50%;
  }
  
}
