.Signin-container {
  width: 100%;
  height: 100vh;
  display: flex;
  .Background-rectangle-image {
    background-color: $signin-background-color;
    width: 30%;
  }
  .RectangleImage {
    background: url(../../../src/images/Vector@2x.svg);
    background-size: 150% 120%;
    background-repeat: no-repeat;
    filter: brightness(0.6);
    width: 100%;
    height: 100%;
  }
  .welcomeContainer{
    .ant-list-item-meta-description{
      font-size:24px !important;
    }
    .welcome-menu{
      max-width: unset !important;
      .welcome-text-container{
        padding: 30px 10px 30px 114px;
      }
    }
    .welcome-user-image{
      display: flex;
      justify-content: space-between;
     
      .click-next-button{
        position: absolute;
        right: 70px;
        bottom: 100px;
        font-weight: bold;
        font-size: 24px;
        line-height: 100%;
        letter-spacing: 0.1px;
        color: #000000;
        cursor: pointer;
      }
      .click-next-page{
        position: absolute;
        right: 70px;
        bottom: 100px;
        font-weight: bold;
        font-size: 24px;
        line-height: 100%;
        letter-spacing: 0.1px;
        color: #000000;
        cursor: pointer;
      }
      .click-prev-page{
        position: absolute;
        bottom: 100px;
        font-weight: bold;
        font-size: 24px;
        line-height: 100%;
        letter-spacing: 0.1px;
        color: #808080;
        cursor: pointer;
      }
    }
    .click-to-play{
      padding: 45px 0px;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #1D1D2D;
      opacity: 0.5;
    }
  }
  .welcomeContainer{
    height: 100% !important;
  }
  .sideContainer {
    padding: 80px 10px 30px 160px;
    text-align: left;
    background: #ffffff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex: 1;
    font-family: Mulish;
    .sideMenu {
      max-width: 500px;
      .Signin-image {
        .vectorStyle {
          height: 31px !important;
          width: 32.88px !important;
          margin-right: 9px;
        }

        .autismGroupStyle {
          height: 21.57px !important;
          width: 137.62px !important;
        }
      }
      .welcomeText {
        margin-top: 25px;
        .SignInText {
          color: $welcome-text-color;
          font-family: Mulish;
          font-style: normal;
          font-weight: bolder;
          font-size: 40px;
          line-height: 30px;
        }
        .autismText {
          font-family: Mulish;
          font-style: normal;
          font-weight: bold;
          font-size: 48px;
          line-height: 48px;
        }
        .autism360Text {
          font-family: Mulish;
          font-style: normal;
          font-weight: 400;
          font-size: 48px;
          line-height: 48px;
        }
        .credentialsText {
          font-family: Mulish;
          font-style: normal;
          font-weight: normal;
          font-size: 24px;
          line-height: 30px;
          letter-spacing: 0.1px;
          color: #1d1d2d;
          margin-top: 16px;
          margin-bottom: 30px;
          opacity: 0.5;
        }
        .verificationCredentialsText {
          font-family: Mulish;
          font-style: normal;
          font-weight: normal;
          font-size: 24px;
          line-height: 30px;
          letter-spacing: 0.1px;
          color: #1d1d2d;
          margin-top: 40px;
          margin-bottom: 30px;
          opacity: 0.5;
        }
        .ForgotCredentialsText {
          font-family: Mulish;
          font-style: normal;
          font-weight: normal;
          font-size: 24px;
          line-height: 30px;
          letter-spacing: 0.1px;
          color: #1d1d2d;
          margin-top: 30px;
          margin-bottom: 50px;
          opacity: 0.5;
        }
        .ResetCredentialsText {
          width: 430px;
          font-family: Mulish;
          font-style: normal;
          font-weight: normal;
          font-size: 24px;
          line-height: 30px;
          letter-spacing: 0.1px;
          color: #1d1d2d;
          margin-top: 30px;
          margin-bottom: 50px;
          opacity: 0.5;
        }
        .BackButton {
          color: $button-color;
        }
      }
      .remember-forgot-container {
        width: 100%;
        display: flex;
        font-weight: 700;
        .remember-me {
          margin-top: 0px;
          width: 50%;
          .is-sign-in{
            color: #1D1D2D;
            font-size: 14px;
          }
        }
        .forget-pass {
          display: flex;
          justify-content: flex-end;
          margin-top: 0px;
          width: 50%;
          a {
            color: #1D1D2D;
            font-size: 14px;
          }
        }
        .welcome-screen {
          display: flex;
          margin-top: 120px;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          text-align: right;
          letter-spacing: 0.1px;
          color: #1d1d2d;
          .sign-up {
            color: $link-color !important;
          }
         
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .Signin-container {
    width: 100%;
    height: 100vh;
    display: block;
    .welcomeContainer{
      // padding: 55px 40px !important;
      .welcome-user-image {
       
        position: relative;
        justify-content: space-between;
        .click-prev-page{
          left: 50px;
          bottom: 70px;
          position: fixed;
          font-weight: bold;
          font-size: 24px;
          line-height: 100%;
          letter-spacing: 0.1px;
          color: #808080;
        }
        .click-next-page {
          right: 50px;
          bottom: 70px;
          position: fixed;
          font-weight: bold;
        font-size: 24px;
        line-height: 100%;
        letter-spacing: 0.1px;
        color: #000000;
      }
      .RectangleImage {
        background: url(../../../src/images/Vector@2x.svg);
        background-size: 150% 120%;
        background-repeat: no-repeat;
        filter: brightness(0.6);
        width: 100%;
        height: 100vh;
      }
        .welcome-image-vector{
          margin-top:30px;
        }
        .click-next-button {
         
          right: 0px;
         
      }
    }
    .click-to-play{
    padding:0px !important;
    top: 0px;
    position: absolute;
    }
    .welcome-menu .welcome-text-container {
       .wizard-text{
         line-height: 15px !important;
       }
     }
    }

    .Background-rectangle-image {
      background-color: $signin-background-color;
      width: 100%;
      height: 300px;
    }
    .RectangleImage {
      background: url(../../../src/images/VectorMobile.svg);
      background-size: 165% 73%;
      background-repeat: no-repeat;
      filter: brightness(0.6);
      width: 100%;
      box-shadow: 0px 8px 8px rgb(29 29 45 / 25%);
    }
    .ant-col-16 {
      flex: 100%;
      max-width: 100%;
    }
    .sideContainer {
      padding: 20px 40px;
      text-align: left;
      background: #ffffff;
      width: 100%;
      font-family: Mulish;
      .sideMenu {
        width: 100%;
        margin: 0px auto;
        // top: -220px;
        .Signin-image {
          display: block;
          position: absolute;
          top: 35px;
          left: 40px;
          .vectorStyle {
            height: 31px !important;
            width: 32.88px !important;
            margin-right: 10px;
          }

          .autismGroupStyle {
            // background: url(../../../src/images/autism_360White.svg) !important;
            height: 21.57px !important;
            width: 137.62px !important;
            background-repeat: no-repeat;
          }
        }
       
        .welcomeText {
          
          .SignInText {
            color: #ffffff;
            font-family: Mulish;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 30px;
          }
          .autismText {
            font-family: Mulish;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            color: #ffffff;
            line-height: 30px;
          }
          .autism360Text {
            font-family: Mulish;
            font-style: normal;
            font-weight: 400;
            color: #ffffff;
            font-size: 24px;
            line-height: 30px;
          }
          .credentialsText {
            font-family: Mulish;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.1px;
            color: #ffffff;
            margin-top: 30px;
            margin-bottom: 50px;
            opacity: 0.5;
          }
          .verificationCredentialsText {
            margin-top: 220px;
          }
          .ForgotCredentialsText {
            width: 397px;
            font-family: Mulish;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.1px;
            color: #ffffff;
            margin-top: 30px;
            margin-bottom: 50px;
            opacity: 0.5;
          }
          .ResetCredentialsText {
            width: 430px;
            font-family: Mulish;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.1px;
            color: #ffffff;
            margin-top: 30px;
            margin-bottom: 50px;
            opacity: 0.5;
          }
          .BackButton {
            color: $button-color;
            background: transparent;
          }
        }
        .is-sign-in {
          margin-top: 0px;
          font-size: 12px !important;
          
        }
        .forget-pass {
          line-height: 20px;
          a {
            
            font-size: 12px !important;
          }
        }
        .welcome-screen {
          display: flex;
          margin-top: 120px;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          text-align: right;
          letter-spacing: 0.1px;
          color: #1d1d2d;
          .sign-up {
            color: $link-color !important;
          }
          
        }
      }
    }
  }
}
@media only screen and (max-width: 992px) and (min-width:600){
  .wizard-getting-started {
    
    .welcomeContainer{
      padding:60px;
    }
  }

}
@media only screen and (max-width: 599px) and (min-width:320){
  .wizard-getting-started {
    
    .welcomeContainer{
      padding:25px;
    }
  }

}
@media only screen and (max-width: 500px) {

  .Signin-container {
    
    .welcomeContainer{
      .ant-list-item-meta-title{
        font-size:20px !important;
      }
      .ant-list-item-meta-description{
        font-size:16px !important;
      }
      .welcome-user-text{
        font-size:16px !important;
      }

      .click-to-play{
        font-size:12px !important;
      } 

      .welcome-user-image {
        .click-next-button {
          bottom:2px !important;
          font-size:16px !important;
          >img{
            width:20px !important;
          }
        }

        .click-prev-page {
          font-size:20px;
          >img{
            width:20px;
          }
        }
        .click-next-page {
          font-size:20px;
          >img{
            width:20px;
          }
        }
      }

    }
  }
}
