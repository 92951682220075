.autismag-sidebar-show-sidebar {
  box-shadow: 4px 0px 16px rgba(16, 30, 115, 0.08);
  .ant-menu-item > span > a {
    color: black !important;
  }
  .ant-select-selector {
    background: #f5b04a;
    box-shadow: 0px 4px 4px rgb(29 29 45 / 25%);
    border-radius: 20px;
    color: #ffffff;
  }
  .ant-layout-sider-children {
    .block-header-icon {
      .ant-btn {
        .vectorStyle {
          height: 31px !important;
          width: 31px !important;
          
        }
        .vectorStyle .ant-menu-item >span:hover{
          background-color: #000000b3;
        }
      }
    }

    .sider-display {
      display: flex !important;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      overflow: auto;
    }
  }
  .ant-menu.ant-menu-dark .ant-menu-item-selected > span > a {
    // color: #fff !important;
  }

  
  .ant-menu-dark .ant-menu-submenu-title:hover {
    color: black !important;
  }
  .ant-menu-dark .ant-menu-submenu-selected {
    color: black !important;
  }
  .ant-menu-dark .ant-menu-submenu-open {
    color: black !important;
  }
  .ant-menu-dark .ant-menu-item > a {
    color: black !important;
  }
  .ant-menu-item.ant-menu-item-selected > a {
    // color: #fff !important;
  }
  li.ant-menu-item.ant-menu-item-selected > a {
    // color: #fff !important;
  }
  .ant-menu-dark .ant-menu-item > span {
    color: black !important;
  }
  .ant-menu.ant-menu-dark
    .ant-menu-submenu-title
    .ant-menu-submenu-arrow::before {
    background: black !important;
  }
  .ant-menu.ant-menu-dark
    .ant-menu-submenu-title
    .ant-menu-submenu-arrow::after {
    background: black !important;
  }
  .ant-menu-submenu-popup > .ant-menu {
    background: #001529 !important;
  }
  .userSelector {
    .ant-select-selector {
      border: none !important;
      background: none !important;
      color: #ffffff;
      width: 120px;
    }
  }
}
.autismag-sidebar {
  .ant-layout-sider-children {
    .block-header-icon {
      .ant-btn {
        .vectorStyle {
          height: 31px !important;
          width: 31px !important;
        }
      }
    }
  }
  .ant-select-selector {
    background: #f5b04a;
    box-shadow: 0px 4px 4px rgb(29 29 45 / 25%);
    border-radius: 20px;
    color: #ffffff;
  }
  .hide-logo {
    span.ant-select-selection-item {
      padding-right: 0px;
      padding-left: 10px;
    }
    span.ant-select-arrow {
      display: none;
    }
  }
}
.userCollapse.ant-menu-item-icon {
  padding-left: 10px;
}
.imageCollapse.ant-menu-item-icon {
  padding-right: 34px;
}

.ant-layout-sider-children {
  height: 100vh;
  background: #f9fcfd;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.ant-layout-sider-children .block-header-icon {
  background: #ffffff;
  min-height: 81px;
  padding-left: 22px !important;
  align-items: center;
}

li.ant-menu-item.ant-menu-item-selected {
  background:#00adee;
  box-shadow: 0px 4px 8px #caf2ff;
}

.select-user-dropdown {
  background: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 16px;
  border-radius: 0px 0px 10px 10px;
  padding: 16px 20px;
}

.select-user-dropdown-option {
  font-family: Mulish;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #1d1d2d;
  opacity: 0.5;
  /* opacity: 0.1; */
  border-bottom: 1px solid #1d1d2d42;
}
.ant-select-item.ant-select-item-option.select-user-dropdown-option.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 900;
  background-color: rgb(46 46 141 / 28%);
  font-family: Mulish;
  font-style: normal;
  opacity: none;
  // font-weight: bold;
}
// .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
// margin-left: 20px;
//     padding: 0px 12px;
//     margin-right: 10px;
// }
.userGradient {
  background: linear-gradient(270deg, #75388d 0%, #3f84c5 100%) !important;
}
// li.ant-menu-item.ant-menu-item-selected {
//     background: linear-gradient(
// 270deg
// , #75388D 0%, #3F84C5 100%) !important;
// }

.ant-layout-sider {
  background: none !important;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: #000000 !important;
  background: none !important;
}

ul#sub1\$Menu.ant-menu-vertical {
  background: #001529 !important;
}

.menu-icon {
  width: 115px !important;
  height: 31px !important;
  margin-top: 24px !important;
  margin-left: 5px;
}
.dashboard-main-layout {
  padding-top: 35px;

  .mobile-route-label {
    display: none;
  }
}
.dashboard-inner-layout {
  justify-content: flex-start !important;
}
.dashboard-header-user {
  .header-avatar {
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }
}
.search-box-main-container {
  position: absolute;
  background: #ffffff;
  border: 1px solid rgba(29, 29, 45, 0.1);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  width: 70%;
  height: 55px;
  left: 25px;
  top: 80px;
  padding: 8px 25px;
  img {
    margin-right: 20px;
  }
  input {
    border: none;
    font-size: 13px;
    width: 90%;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #1d1d2d;
  }
  input:focus {
    outline: none;
  }
}

.search-menu-item {
  display: flex;
  align-items: center;

  .search-mobile-response {
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 1042px) {
  .resource-card-container {
    display: flex;
    flex-direction: column;
    width: auto !important;
    flex: 1;
    padding: 11px 0px 0px 17px;
  }
  .select-user-dropdown {
    z-index: 99999;
  }
  .resource-card-container .icon-tab-text {
    position: relative !important;
    left: 0px !important;
    white-space: nowrap;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .autismag-sidebar-show-sidebar .ant-layout-sider-children {
    background: #000000b3;
    height: 100%;

    li.ant-menu-item.ant-menu-item-selected {
      box-shadow: none !important;
    }
  }
  .autismag-sidebar .ant-layout-sider-children {
    background: transparent;
  }

  .autismag-sidebar-show-sidebar .ant-layout-sider-children .sider-display {
    display: block;
    background: white;
    width: 200px;
    height: 100%;

    .ant-select-selector {
      border-radius: inherit;
    }
  }
  .autismag-sidebar .ant-layout-sider-children .sider-display {
    display: none;
  }
  .autismag-sidebar .ant-layout-sider-children .block-header-icon {
    background: transparent;
  }
  .autismag-sidebar-show-sidebar {
    position: fixed !important;
    z-index: 9999 !important;
  }
  .autismag-sidebar {
    position: absolute !important;
    z-index: 99999 !important;
  }
  section.ant-layout.site-layout {
    margin: 0px !important;
  }
  .dashboard-main-layout {
    padding-top: 30px;
    background: #fff;

    .mobile-route-label {
      display: inline-block;
      margin-left: 20px;
      margin-bottom: 0px;
    }
  }
  .dashboard-inner-layout {
    justify-content: flex-start !important;
    margin-bottom: 20px;
    margin-top: 0px;
    .setting-filter {
      margin-top: 20px;
    }
  }
  .dashboard-main-layout {
    .site-card-wrapper {
      // margin-top:20px;
    }
  }

  .dashboard-header-user {
    .dashboard-header-user-profile {
      display: none;
    }
    .anticon-caret-down {
      display: none;
    }
  }

  .ant-layout-sider-children .block-header-icon {
    min-height: 56px;
    border-bottom: 0px;
    align-items: center;

    .mobile-menu {
      .hamburgerIcon {
        margin-right: 15px;
      }
    }

    button.ant-btn.ant-btn-text {
      padding: 0px;
      margin: 0px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .search-mobile-response {
        padding: 0px;
        margin: 0px !important;
        display: flex;
        align-items: center;
      }

      .collapseHeader {
        border: none;
        background: none;

        .ant-collapse-item {
          border-bottom: none !important;

          .ant-collapse-header {
            padding-left: 15px;
            padding-top: 0px;
            padding-bottom: 0px;

            img.ant-collapse-arrow {
              padding: 0px;
              position: relative;
              top: 0px;
              left: 0px;
            }
          }
        }
      }
    }
  }

  .menu-icon {
    width: 100px !important;
    height: 31px !important;
    margin-top: 8px !important;
    margin-left: 5px;
  }

  .autismag-sidebar-show-sidebar
    .ant-layout-sider-children
    .block-header-icon
    .ant-btn
    .vectorStyle {
    height: 25px !important;
    width: 25px !important;
  }

  .dashboard-header-user {
    .header-avatar {
      width: 30px;
      height: 30px;
      margin-right: 0px;
    }
  }

  .search-mobile-response {
    margin: 9px 10px !important;
    img {
      width: 18px;
      opacity: 0.5;
    }
  }

  .search-box-main-container {
    width: 100%;
    height: 40px;
    left: 0px;
    top: 51px;
    z-index: 9999;
    img {
      width: 18px;
      margin-right: 10px;
    }
  }
}

.Main-Dashboard-content-container th.ant-table-cell {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  background: #f8fbfc;
  border: none;
}

.onboard-from-title-style {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #1d1d2d;
}

.onboard-from-title-style:hover {
  color: #f5b04a;
}

.special-truncate-style-container {
  white-space: nowrap;
  width: 250px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 992px) {
  .dashboard-inner-layout {
    justify-content: flex-start !important;
    margin-bottom: 0px;
  }
  .session-tab-text {
    position: absolute;
    left: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.1px;
    color: #1d1d2d;
    white-space: nowrap;
    width: 149px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .Main-Dashboard-content-container {
    padding-top: 20px;
  }
  .resource-card-container .icon-tab-text {
    position: relative !important;
    left: 0px !important;
    white-space: nowrap;
    width: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 767px) {
  .resource-card-container .icon-tab-text {
    position: relative !important;
    left: 0px !important;
    white-space: nowrap;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .session-tab-text {
    position: absolute;
    left: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.1px;
    color: #1d1d2d;
    white-space: nowrap;
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .select-box .ant-select-selector {
    width: 50%;
  }
  .resources-table {
    .ant-table-row {
      position: relative;
      background:#FFFFFF;
    }
  }
  .resources-table {
    .ant-table-row-expand-icon-cell {
      // position: absolute;
      // right: 0px;
    }
  }
  .resources-table {
    .ant-table-content td {
      min-height: none !important;
    }
  }
  .resources-table{
    .ant-table-tbody{
      background:#F8FBFC !important;
      border-radius: 10px;
    }
  }
}

.days-in-program-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px;
  text-align: center;
  font-size: 1.5em;
  
  .days{
    //font-size: 1.2rem;
    padding: 0 .5rem;
    font-weight: 500;
    color: #67348E
  }
  .progress-sub-list{
    font-size: .8rem;
    color: #888;
  }
}