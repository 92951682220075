
.MainLoginButton{
    display: flex;
    justify-content: center;
    .LoginButton{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 224px !important;
        height: 48px;
        background: $button-color;
        box-shadow: 0px 4px 8px rgba(29, 29, 45, 0.25);
        border-radius: 24px;
        border: none !important;
    }
}

.btn-border {
    border: 1px dotted #f59e31;
    color: #f59e31;
}

.btn-outlined-orange {
    &:hover, &:focus {
      border: 1px solid #f5b04a;
      color: #f5b04a;
    }

    &:active {
      border: 1px solid #db8e1a;
      color: #db8e1a;
    }

    &.active {
      background: #f5b04a;
      color: white;
      border: 1px solid #f5b04a;
    }

    &.active:hover, &.active:focus {
      color: white;
    }
  }