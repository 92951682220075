.interactions-container {
  overflow-y: auto;
  max-height: 400px;
  border: 1px solid rgba(29, 29, 45, 0.1);
  border-radius: 20px;
  padding: 8px 11px;
  margin-bottom: 12px;

  .interaction-message {
    background: #F9F9F9;
    border-radius: 18px;
    padding: 6px 8px;
    margin-top: 8px;
    width:fit-content;

    .user-type-tag {
      padding: 2px 4px;
      border-radius: 3px;
      font-size: 11px;
      font-weight: 600;
      margin-right: 4px;
      
      &.therapist {
        color: #2400FF;
        background: #216CFF21;
      }
      
      &.poster {
        color: #FF9F21;
        background: #FF9F2121;
      }
    }

    .internal-deeplink {
      color: #f59e31;
      font-size: 11px;
      text-decoration: underline;
    }
  }
}