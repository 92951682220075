@import "./colors.scss";

@import "./components/buttons.scss";

@import "./components/signIn.scss";

@import "./components/input.scss";

@import "./components/dashboard.scss";

@import "./components/form.scss";

@import "./components/drawer.scss";

@import "./components/milestone.scss";

@import "./components/wizard.scss";

@import "./components/videoPlayer.scss";

@import "./components/tables.scss";

@import "./components/session.scss";

* {
    font-family: 'Mulish', sans-serif !important;
}
